<template>
  <DialogFormWrapper>
    <template #form>
      <v-form
        id="assignContainerForm"
        ref="assignContainerForm"
        class="custom-form pt-8"
        @submit.prevent="assignContainer"
      >
        <v-row>
          <v-col cols="12">
            <EditContainerId
              :data="order"
              @update="containerId = $event.containerId"
            />
          </v-col>
        </v-row>
      </v-form>
    </template>
    <template #submit>
      <v-btn
        color="primary"
        class="base-hover"
        :loading="isProcessing"
        type="submit"
        form="assignContainerForm"
      >
        Przypisz kontener
      </v-btn>
    </template>
  </DialogFormWrapper>
</template>

<script>
import DialogFormWrapper from './Partials/DialogFormWrapper'
import EditContainerId from '../Forms/Inputs/ContainerId.vue'
import { mapState, mapActions } from 'vuex'

export default {
  components: {
    DialogFormWrapper,
    EditContainerId
  },
  data () {
    return {
      containerId: null,
    }
  },
  computed: {
    ...mapState({
      isProcessing: state => state.order.isProcessing,
      item: state => state.layout.dialog.item,
    }),
    order () {
      return this.item.order || this.item
    }
  },
  methods: {
    ...mapActions({
      assignContainer (dispatch) {
        const payload = {
          id: this.order.id,
          params: { containerId: this.containerId }
        }
        return dispatch('order/assignContainer', payload)
      },
    })
  }
}
</script>
